/**
 * Wrapper for autosearch, used on GAYL site for store search box in header
 * 
 * Nb. the cookie is base64 encoded because the JSON format was triggering this error:
 * org.apache.tomcat.util.http.Cookies processCookieHeader
 * INFO: Cookies: Invalid cookie. Value not a token or quoted value
 * Note: further occurrences of Cookie errors will be logged at DEBUG level.
 */

 'use strict';

const buildIcon = require("Scripts/common/build-icon");
const AutoSearch = require("Scripts/common/autosearch.js");
const Cookie     = require('Vendor/js-cookie');

module.exports = Searchbox;

function Searchbox(form) {
	this.init = function() {
		this.form  = form || $("#js-nav-search");

		if(this.form.length) {
			const options = {
				form: this.form,
				history: true,
				parent: this,
				url: options => `/merchant/autocomplete?q=${encodeURIComponent(options.input.val())}&rows=${options.form.data('rows') || 5}`,
				html: data => {
					let clearIcon = data.value.date ? ` <span role="button" class="searchbox-clear" title="Remove" data-id="${data.value.id}">` + buildIcon("fa-circle-xmark") + `</span>` : '';
					return `<a href="${data.value.pagePath}">${data.match.name}${clearIcon}</a>`
				},
				onSelect: data => {
					// So that the clear icon works, we have to check what's being clicked
					let tag = data.event.target.tagName;
					if(tag == "A" || tag == "INPUT") {
						window.open(data.selection.value.pagePath, "_self");
					}
				}
			}

			const search = new AutoSearch(options);

			this.addHistory();
		}
	}

	this.addHistory = function() {
		try {
			let data = this.getHistoryData();
			if(!data.id) return;

			console.log("Searchbox.addHistory()", data.id);

			let list = this.getHistoryList(data.id);
			list.unshift(data);
			this.updateHistoryCookie(list);
		}
		catch(err) {
			return [];
		}
	}

	this.getHistoryData = function() {
		let data = $("#js-search-history").data();
		if(!data) return false;
		return data;
	}

	this.updateHistoryCookie = function(list) {
		list = JSON.stringify(list.slice(0,9));
		Cookie.set('gayl-searchbox-stores', btoa(list), { expires: 30 });
	}

	this.getHistoryList = function(id) {
		try {
			console.log("Searchbox.getHistoryList()", id);
			let cookie = Cookie.get('gayl-searchbox-stores');
			if(cookie && cookie.length) {
				return JSON.parse(atob(cookie)).filter(item => item.id != id && !item.name.includes('escapedQuery'));
			}
		}
		catch(err) { /* Nothing */ }
		return [];
	}

	this.clearHistoryId = function(id) {
		console.log("Searchbox.clearHistoryId()", id)
		let list = this.getHistoryList().filter(item => item.id != id);
		this.updateHistoryCookie(list);
	}

	this.init();
}