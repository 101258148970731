const AnalyticsEvent = require('Scripts/common/analytics-event').default;

module.exports = function Video() {

	$('.js-video-prompt').on('click', (e) => {
		let prompt = $(e.currentTarget);
		let video = $(prompt.data('target')).get(0)

		$(video).off().on("play", () => {
			console.log("Video played");
			pipCheck(video);
			trackEvents(video, "play");			
		});
		
		$(video).on("pause abort ended", (e) => {
			console.log("Video " + e.type);
			$(window).off("scroll.video");
			resetCss(video);
			trackEvents(video, e.type);
		});

		video.play();
		prompt.remove();
	});

	function isOffScreen(video) {
		let parent = $(video).parent().parent();
		let buffer = parent.height() / 2;
		let above = (parent.offset().top + buffer) < $(window).scrollTop();
		let below = buffer + parent.offset().top - $(window).scrollTop() - $(window).height() > 0;
		return above ? "above" : (below ? "below" : false);
	}

	function pipCheck(video) {
		$(window).off("scroll.video").on("scroll.video", () => {
			if($(video).data("pip") && video.requestPictureInPicture){

				let whereOnScreen = isOffScreen(video);

				if(whereOnScreen && !$(video).hasClass("video-pip--above") && !$(video).hasClass("video-pip--below")) {
					console.log("Video()", "Off screen, activate PIP", whereOnScreen);
					$(video).outerWidth(Math.ceil($(video).outerWidth()));
					$(video).addClass("video-pip--" + whereOnScreen);
				}
				else if(!whereOnScreen) {
					console.log("Video()", "On screen, remove PIP");
					resetCss(video);
				}
			}
		});
	}

	function resetCss(video) {
		$(video).css('width', "");
		$(video).removeClass("video-pip--above");
		$(video).removeClass("video-pip--below");
	}

	function trackEvents(video, eventName) {
		eventName = eventName + 'Video';
		let data = {videoId: video.id, videoDuration: Math.round(video.currentTime)};
		AnalyticsEvent.heap(eventName, data);
		AnalyticsEvent.gtm(eventName, data);
	}
}