// Autocomplete for search boxes
// Options requires: form, url (func), html (func), and onSelection (func)
// Option optionally uses className, input, and resultsDest if different from defaults

const autoComplete = require("Vendor/@tarekraafat/autocomplete.js");

module.exports = AutoSearch;

function AutoSearch(options) {
	this.init = function(options) {
		if(!options || !options.form || !options.form.length) {
			console.log("AutoSearch requires options object");
			return;
		}

		// Some defaults/fallbacks
		options.className   = options.className || "searchbox-results";
		options.input       = options.input || options.form.find("input");
		options.resultsDest = options.resultsDest || options.form.find(".input-group");

		if(!options.resultsDest.length) {
			options.resultsDest = options.form;
		}

		// Disable autocomplete if not already done in HTML
		options.input.attr("autocomplete", "off");
		options.input.attr("spellcheck", "false");

		// Checking the term length is used a few times, so in a function
		const termLength = () => options.input.val().length;

		// The search function. Previously this was an async/await function but it caused issues with Babel when compiling for production
		const search = () => {
			// Open up the history
			if(termLength() < 2) {
				return new Promise((resolve) => {
					resolve(options.history && termLength() == 0 ? options.parent.getHistoryList(options.parent.getHistoryData().id || null) : []);
				});
			}

			// Call the API
			return fetch(options.url(options))
			.then(response => {
				if(response.ok) {
					return response.json();
				}
				throw new Error("Not 2xx response");
			})
			.then(data => data);
		}

		// Set up the auto complete dropdown
		let ac = new autoComplete({
			selector: () => options.input[0],
			data: {
				src: search,
				key: ["name"],
			},
			threshold: 0, // Because we want to show recent searches, therefore the threshold is managed in search()
			wrapper: false,
			searchEngine: (query, record) => {
				return record; // This stops Autosearch comparing the returned results with the query
			},
			resultsList: {
				element: (list) => {
					if(options.history && termLength() == 0) {
						const historyHeader = document.createElement("span");
						historyHeader.setAttribute("class", "searchbox-recent");
						historyHeader.innerHTML = "Recent searches";
						list.children[0].prepend(historyHeader);
					}
				},
				destination: () => options.resultsDest[0],
				tag: "ul",
				class: options.className,
				position: "afterend",
				maxResults: 20,
			},
			resultItem: {
				element: (item, data) => {
					item.innerHTML = options.html(data);
				},
			},
			events: {
				input: {
					focus(e) {
						ac.start(); // This forces it to start, even if you haven't typed anything yet. This allows us to show the history.
					}
				}
			},
			// submit: true, // Should make enter work, but in reality it searches even when selecting a result which isn't what we want, so we use the event below (keyCode 13)
		});

		// HACK: because of issues with pressing enter on a result, this allows us to enable/disable submit
		let allowSubmit = true;

		// What happens when you press enter on an autocomplete row. Due to a bug when opening/closing multiple times, the isOpen check is needed.
		options.input.on("selection", e => {
			if(ac.isOpen) {
				options.onSelect(e.detail);
				allowSubmit = false;
			}
		});

		// Annoying bug where if you hold down backspace, it reopens (only happens if connection is slow, e.g. on test/dev!)
		options.input.on("keydown", e => {
			if(e.keyCode === 13 && allowSubmit) {
				options.form.submit();
			}
			else if(e.keyCode === 8 && ac.isOpen && options.input.val().length < 2) {
				ac.close();
			}
		});

		// Toggle for aria-expanded attribute, which is used by the CSS
		options.input.on("open close", e => {
			options.form.attr("aria-expanded", e.type == "open");
		});

		// Set up history clearing
		if(options.history) {
			options.form.find(".searchbox-results").off().on("click", e => {
				let clearIcon = $(e.target).closest(".searchbox-clear");
				options.parent.clearHistoryId(clearIcon.data("id"));
				clearIcon.closest("li").remove();
				clearIcon.closest("input").focus();
				allowSubmit = true; // As it triggers the 'selection' event
				ac.start();
				e.stopPropagation();
				return false;
			});
		}

	}

	this.init(options);
}