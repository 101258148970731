// Main.js

'use strict';

const Cookie      = require('Vendor/js-cookie');
const OwlCarousel = require('Scripts/common/owl-carousel');

module.exports = Main;

function Main() {
	var self = this;
	window.GAYL = window.GAYL || {};

	this.init = function() {
		// Anything else
		self.favsModal();

		// Must go last as it waits for page to load first
		this.onLoad();

		// Bookmark bubble, used on multiple pages so not put in Install.js
		$('.js-homescreen').on('click', this.clickHomescreen.bind(this));

		// Smart banner, used on multiple pages so not put in Install.js
		this.smartBanner = $('#smart-banner');
		this.smartBanner.on('click', this.clickSmartBanner.bind(this));
	}

	this.onLoad = function() {
		$(window).on('load', function () {
			// Check for Donation Reminder
			GAYL.barIsInstalled = !!$('#giveasyoulive_installed').length;

			// Detect if iPad OS (which identifies as macOS)
			GAYL.iPadOS = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;

			// Show/hide stuff based on DR being installed
			if (GAYL.barIsInstalled) {
				$('.js-shoppingbar-installed').collapse('show');
				self.removeDRModal();
			} 
			else if(!GAYL.iPadOS) {
				$('.js-shoppingbar-notinstalled').collapse('show');
			}
			else {
				self.removeDRModal();
			}

			// Check for Adblock
			GAYL.adblockEnabled = !$('#adblock_not_enabled').length;

			// Messages
			console.log("adblockEnabled?", GAYL.adblockEnabled);
			console.log("barIsInstalled?", GAYL.barIsInstalled);
		});
	}

	this.removeDRModal = function() {
		$('#modal-bounce-shoppingbar').remove();
	}

	this.favsModal = function() {
		var modal     = $("#js-favourite-stores-modal");
		var spinner   = modal.find(".modal-body-please-wait");
		var container = $("#js-favourite-stores-container");

		// Set up nav bar link event handler
		$(".js-view-favourite-stores").on("click", function () {
			console.log("Show favourite stores modal");
			container.hide();
			spinner.show();
			modal.modal('show');
		});

		// This is called when the modal show animation has finished
		modal.on("shown.bs.modal", function() {
			console.log("Populate favourite stores modal");

			$.ajax("/account/favourite-stores-modal-ajax").done(function(response) {
				// Hide spinner and insert the HTML
				spinner.hide();
				container.html(response);
				container.show();
				
				// Re-initiate OwlCarousel
				new OwlCarousel();
			});
		});
	}

	// If on Android and they've clicked the add to homescreen button
	this.clickHomescreen = function() {
		console.log("Main.clickHomescreen()");
		Cookie.set('show-bubble', true, {expires:0.0001});
		window.location.href = "/";
	}

	// If on app-supporting device and they click the smart banner
	this.clickSmartBanner = function() {
		console.log("Main.clickSmartBanner()");
		this.smartBanner.collapse();
		Cookie.set('hide-smart-banner', true, {expires:365});
	}

	self.init();
}