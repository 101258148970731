/*!
 * JavaScript for GAYL Online site
 * Give as you Live Ltd
 */

// Import SASS/SCSS styles
import 'Sass/gayl.scss';

// Import modules
const Animate              = require("Scripts/common/animate");
const Bounce               = require("Scripts/common/bounce");
const CharityChooser       = require("Scripts/common/charity-chooser");
const CharityConsentModal  = require('Scripts/common/charity-consent-modal');
const CorporateCharityList = require('Scripts/common/corporate-charity-list');
const Donations            = require('Scripts/common/donations');
const DropdownMenu         = require('Scripts/common/nav-dropdown');
const EmailSettings        = require('Scripts/common/email-settings');
const FacebookLogin        = require('Scripts/common/facebook-v2');
const Form                 = require('Scripts/common/form');
const FormUtils            = require('Scripts/common/form-utils');
const GdprConsentModal     = require('Scripts/common/gdpr-consent-modal');
const Global               = require('Scripts/common/global');
const Loader               = require('Scripts/common/loader');
const LoginSignup          = require('Scripts/common/login-signup');
const MiniNav              = require('Scripts/common/mini-nav');
const MobileMenu           = require('Scripts/common/nav-mobile');
const OwlCarousel          = require('Scripts/common/owl-carousel');
const PersonalDetails      = require('Scripts/common/personal-details');
const Share                = require('Scripts/common/share');
const ShowHide             = require('Scripts/common/showhide');
const SupportTicket        = require('Scripts/common/support-ticket');
const Video                = require('Scripts/common/video');

// Import GAYL Online specific modules
const C4CInstead           = require('Scripts/gayl/c4c-instead');
const Christmas            = require('Scripts/gayl/christmas');
const Claims               = require('Scripts/gayl/claims');
const Dashboard            = require('Scripts/gayl/dashboard');
const FavouriteStores      = require('Scripts/gayl/favourite-stores');
const Install              = require('Scripts/gayl/install');
const Main                 = require('Scripts/gayl/main');
const Offers               = require('Scripts/gayl/offers');
const PreShop              = require('Scripts/gayl/pre-shop');
const Profile              = require('Scripts/gayl/profile');
const Searchbox            = require('Scripts/gayl/searchbox');
const StoreSearch          = require('Scripts/gayl/store-search');
const Switch               = require('Scripts/gayl/switch');
const Voucher              = require('Scripts/gayl/voucher');

// Define groups of modules - the group name is set in your template ($jsModuleGroup). 
// This is just a nicer way to load JS on specific pages without looking at the path/params
// To initiate a group, use the #module macro. E.g. #module("my-group") would add 'my-group' from the groups below.
const moduleGroups = {
	// Available on every page
	'global':            [Global, DropdownMenu, Main, MobileMenu, OwlCarousel, PreShop, Searchbox, ShowHide, Voucher],
	// Available on specific pages
	'animate':           [Animate],
	'auth':              [LoginSignup, FacebookLogin],
	'bounce':            [Bounce],
	'c4c-instead':       [C4CInstead],
	'charity-chooser':   [CharityChooser],
	'christmas':         [Christmas],
	'claims':            [Claims],
	'dashboard':         [Dashboard, GdprConsentModal, MiniNav],
	'donations':         [Donations],
	'email-settings':    [EmailSettings],
	'favourites':        [FavouriteStores],
	'form-utils':        [FormUtils],
	'install':           [Install],	
	'offers':            [Offers],
	'personal-details':  [PersonalDetails],
	'profile':           [Profile, FavouriteStores],
	'public-charity':    [CharityConsentModal],
	'public-corporate':  [CorporateCharityList],
	'refer':             [Share],
	'store':             [FavouriteStores, MiniNav],
	'store-search':      [StoreSearch, FavouriteStores],
	'support-ticket':    [SupportTicket],
	'switch':            [Switch],
	'video':             [Video],
};

Loader.load(moduleGroups);