// JavaScript for /switch and /switch/xxx pages

'use strict';

module.exports = Switch;

function Switch() {
	this.init = function() {
	 	$('.js-quote-modal').on('click', this.onShowQuoteModal.bind(this));
 	}

 	// When logged out, we prompt to login when trying a quote - this sets the return URL dynamically
 	this.onShowQuoteModal = function(e) {
 		console.log("Switch.onShowQuoteModal()");

		var button = $(e.currentTarget); 
		var quote  = button.data('quotePage');
		var modal  = $(button.data('bsTarget'));

		if(typeof quote !== undefined && typeof modal !== undefined){	
			var rurl = '?rurl=' + encodeURI(quote);
			$(modal).find('.js-track-prompt-target-login').attr('href', '/login' + rurl);
			$(modal).find('.js-track-prompt-target-signup').attr('href', '/signup' + rurl);	
		}
 	}

	this.init();
}