// JS for all favourite stores stuff on GAYL
// - toggle on store pages (/stores/amazon)
// - toggle on search pages (/search/stores)
// - favourites manager in account (/account/favourite-stores)

//
// Various bits are commented out as only used on the /signup/choose-favourites page which is currently disabled
//

// import Fetcher from "Scripts/common/fetcher";
// const OwlCarousel = require('Scripts/common/owl-carousel');

export default class FavouriteStores {

	constructor(){		
		this.bindEvents();

		// this.favsTabResults = new Fetcher({
		// 	element:  "#js-favs-fetcher",
		// 	callback: this.bindFavTabs.bind(this)
		// });
	}

	// bindFavTabs() {
	// 	// Re-initiate OwlCarousel
	// 	new OwlCarousel();
	// 	this.bindEvents()
	// }

	bindEvents() {
		// Toggling is used in most places - store page, search results, profile wizard
		$('.js-toggle-favourite').off().on('click', this.toggle.bind(this));
		
		// In the account/favourite-stores page, we physically moving favs so need a different function
		$('.js-account-toggle-favourite').off().on('click', this.accountToggle.bind(this));

		$('.js-show-popular').off().on('click', () => {
			let popularTab = new bootstrap.Tab('#popular-stores-tab');
			popularTab.show();
		});

		// Signup page
		// $('.js-favs-cat').off().on('click', this.favsCategories.bind(this));

		// In the account/favourite-stores page, move reorder hidden stores to make stripes work
		this.moveHidden();

		// Set up the drag and drop function
		this.setupDnD();
	}

	// Load list of favourite stores from the API.
	// This is used on the store page initial load to light up the merchant star icons.
	// Callback is executed after loading
	load(callback) {
		console.log("FavouriteStores.load()")
		
		$.ajax("/favourites", { method: "GET" }).done(response => {
			this.favouriteStores = JSON.parse(response);
			if (callback) callback();
		});
	}

	// Determine whether storeId is a favourite (must call load() first)
	isFavourite(storeId) {
		return $.inArray(storeId, this.favouriteStores) != -1;
	}

	// Set or unset a favourite by calling the favourites API
	set(storeId, isFavourite, order) {
		console.log("FavouriteStores.set(storeId, isFavourite, order)", storeId, isFavourite, order)

		$.ajax("/favourite", {
			method: "POST",
			data: {
				storeId: storeId, 
				isFavourite: isFavourite, 
				order: order 
			}
		});
	}

	// Toggle the favs star
	toggle(e) {
		console.log("FavouriteStores.toggle()");

		var fav          = $(e.currentTarget);
		var storeId      = fav.data("mid");
		var wasFavourite = fav.data("isFavourite");
		var isFavourite  = !wasFavourite;

		// Send the request to actually add / remove the favourite
		this.set(storeId, isFavourite, 0);

		// Update the data attributes with the new state so that next this this function is called the element has the correct state.
		fav.data("isFavourite", isFavourite);

		// Update the text
		fav.find('.js-action').html(isFavourite ? "Remove" : "Add");

		// Update the container
		fav.toggleClass("is-fav", isFavourite);

		// Update the triangle
		fav.find(".fav-triangle").toggleClass("is-fav", isFavourite);

		// Update the icon (use attr() not data() as latter is for reading only not writing)
		fav.find('.toggle-star svg').attr('data-prefix', isFavourite ? 'fas' : 'far');
	}

	accountToggle(e) {
		var fav          = $(e.currentTarget);
		var storeId      = fav.data("mid");
		var wasFavourite = fav.data("isFavourite");
		var isFavourite  = !wasFavourite;
		var row          = fav.closest(".js-favourite");
		var isFavsList   = fav.parents("#list-favourites").length; // Rather than Suggested/Popular

		this.set(storeId, isFavourite);

		if(isFavourite){
			// Copy the row then change the buttons round
			var newRow = row.clone();
			newRow.prependTo("#list-favourites");
			newRow.find(".collapse").collapse("toggle"); // Switches round the add/remove buttons
		}
		else if(isFavsList) {
			var newRows = $("#list-suggested, #list-popular").find(".js-favourite-" + storeId);
			newRows.filter(".show").find(".btn-container").collapse("toggle");
			newRows.collapse("show");
		}
		else {
			$("#list-favourites").find(".js-favourite-" + storeId).remove();
		}

		// Set events for once row is hidden (as there's a transition so needs the listener)
		// We only collapse the row if on the favs list, or if click 'hide'
		if(wasFavourite && (fav.parents("#list-favourites").length || fav.hasClass("btn-link"))) {
			row.off().on("hidden.bs.collapse", () => {
				if(isFavourite){
					this.bindEvents(); // init() includes moveHidden()
				}
				else {
					row.remove();
					this.moveHidden();
				}
			});

			// Now that event handler has been set, we can hide row
			row.collapse("hide");
		}
		else {
			this.bindEvents();
		}
		
		// Toggle the button
		row.find(".btn-container").collapse("toggle");

		// Show/hide warning messages depending on number in list
		$("#list-suggested, #list-favourites").each(function(){
			var items   = $(this).find(".js-favourite.show").length;
			var warning = $(this).parent().find(".js-no-favs");

			if((items == 0 && !warning.hasClass("show")) || (items > 0 && warning.hasClass("show")) ){
				warning.collapse("toggle");
			}
		});
	}

	// Move the already selected favs to the tops of their lists. The main reason for this is to make the stripe effect work properly!
	moveHidden() {
		console.log("FavouriteStores.moveHidden()");

		$("#list-suggested, #list-popular").each(function() {
			var section = $(this);

			section.find(".js-favourite:not(.show):not(.collapsing)").each(function() {
				$(this).appendTo(section);
			});
		});
	}

	setupDnD() {
		console.log("FavouriteStores.setupDnD");

		var manageFavouritesContainer = $('#list-favourites');
		if(!manageFavouritesContainer.length) return;

		this.dnd = {
			items: $('#list-favourites > .show'),
			touched: false,
		};

		$.each(this.dnd.items, (i, item) => {
			this.bindDnd(item);	
		});

		$('#list-favourites').on('drop', this.onDrop.bind(this));
	}

	bindDnd(item) {
		item.draggable    = true;
		item.ondragstart  = this.onDragStart.bind(this);
		item.ondragover   = this.onDragOver.bind(this);
		item.ontouchstart = this.onTouchStart.bind(this);
		item.ontouchmove  = this.onTouchMove.bind(this);
		item.ontouchend   = this.onTouchEnd.bind(this);
	}

	onDragStart(e) {
		console.log("FavouriteStores.onDragStart()", e);

		this.dnd.selected = null; // Reset to stop previous events affecting current event
		var selected = $(e.target);
		var favClass = "js-favourite";

		selected = this.getRow(selected, e);
		if(!selected || $(e.target)[0].nodeName == 'A') return;

		selected.addClass('opacity-50');
		this.dnd.index    = selected.index(); // .index() updates as we drag, so need to set the initial index
		this.dnd.selected = selected;
	}

	onDragOver(e) {
		if(!this.dnd.selected) {
			return;
		}

		e.preventDefault(); // Important!
		this.dnd.under = $(e.target).parents(".js-favourite");
		this.moveRows();
	}

	onTouchStart(e) {
		console.log("FavouriteStores.onTouchStart", e);

		var touchX  = e.touches[0].clientX;
		var screenX = window.screen.availWidth;

		// If using touch, allow right-most 30% to be used for scrolling, and also stop if tapping a link (link takes priority anyway)
		if($(e.target)[0].nodeName == 'A' || (touchX / screenX) > 0.7) {
			this.dnd.touched = false;
			return;
		}

		// We check for this in onTouchMove - if an invalid part is being moved, we stop the event and let native scroll take over
		this.dnd.touched = true;
		this.onDragStart(e);
	}

	onTouchMove(e) {
		if(!this.dnd.touched) {
			return;	
		}

		if(e.cancelable) {
			e.preventDefault();
		}

		var touches  = e.touches[0];
		var under    = $(document.elementFromPoint(touches.clientX, touches.clientY));

		under = this.getRow(under, e);
		if(!under) return;

		this.dnd.under = under;
		this.moveRows();
	}

	onTouchEnd(e) {
		// Need to check that onTouchStart was valid, otherwise onDrop will throw an error
		if(!this.dnd.touched) {
			return;	
		}

		this.onDrop(e);
	}

	// Need to ensure we're moving the row and not a child element (e.g. logo, text). If row can't be found, stop other drag events
	getRow(el, e) {
		var favClass = "js-favourite";

		if(!el.hasClass(favClass)) {
			el = el.parents("."+favClass);
		}
		if(!el.hasClass(favClass)) {
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
		return el;
	}

	// Move the row - depending on if we're moving up or down, we use before() or after()
	moveRows() {
		var dragType = this.dragType();
		if(dragType) {
			this.dnd.under[dragType == 2 ? 'before' : 'after'](this.dnd.selected)
		}
	}

	onDrop(e) {
		console.log("FavouriteStores.onDrop()", "Old index", (typeof this.dnd.index !== 'undefined' ? this.dnd.index : null), "New index", (typeof this.dnd.under !== 'undefined' ? this.dnd.under.index() : null));

		e.stopPropagation();

		this.dnd.touched = false;

		if(!this.dnd.selected) {
			return;
		}

		this.dnd.selected.removeClass('opacity-50');

		var newIndex = this.dnd.under.index();

		if(this.dnd.index !== newIndex) {
			this.set(this.dnd.selected.data('mid'), true, newIndex);
		}
	}

	// returns 0 = no movement // 1 = move down // 2 = move up
	dragType() {
		var before = this.dnd.selected.index();
		var after = this.dnd.under.index();
		return before == after ? 0 : before < after ? 1 : 2;
	}

	// favsCategories(e) {
	// 	console.log("FavouriteStores.favsCategories");

	// 	var btn = $(e.currentTarget);
	// 	var cat = btn.data('cat');

	// 	this.favsTabResults.options.data = {'cat':cat};
	// 	this.favsTabResults.fetch();

	// 	btn.toggleClass("btn-blue");
	// 	btn.toggleClass("btn-navy");

	// 	var btns = btn.siblings(".btn-navy");
	// 	btns.toggleClass("btn-blue");
	// 	btns.toggleClass("btn-navy");
	// }
}
