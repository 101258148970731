class Install {
	constructor() {
		this.videos = $('.install-video video');
		this.playVideos(1000);
		this.bindTriggers();
	}

	bindTriggers() {
		$('.js-install-button').on('click', this.clickButton.bind(this));
		$('.js-change-tab').on('click', this.changeTab.bind(this));
		$('.js-show-install-toast').on('click', this.showToast.bind(this));
		$(window).on("scroll", this.playVideos.bind(this));
		$(window).on("shown.bs.modal", this.playVideos.bind(this));
		this.videos.on('ended', this.repeatVideo.bind(this));
	}

	// Clicked install button. It has a href, but if it's the /signup/install page then we auto follow the skip button so they don't have to
	clickButton() {
		console.log("Install.clickButton()", "Normal target opened via href, now checking to see if there's a skip button to follow");
		let skip = $('.js-install-skip').attr("href");

		if(skip) {
			// Toggle the buttons on mobile
			$('.js-app-ctas').collapse('toggle');

			// Using timeout seems to get around popup blockers?
			setTimeout(() => { window.location.href = skip }, 2000);
		}
	}

	// Play vide when tab changes. We first reset back to first frame. Also does some background colour stuff.
	changeTab(e) {
		console.log("Install.changeTab()");
		let target     = $($(e.currentTarget).data('bsTarget'));
		let videos     = target.find("video");
		let colour     = target.data('colour');
		let background = $('.js-background');

		if(background && colour) {
			background.removeClass((i, className) => {
				return (className.match(/(^|\s)bg-\S+/g) || []).join(' ');
			});

			background.addClass(colour);
		}

		videos.each((k, video) => {
			video.pause();
			video.currentTime = 0;
			this.playVideo(video, 500);
		});
	}

	// Play videos when visible (checks that 80% of video height is in view)
	playVideos(delay) {
		this.videos.each((k, video) => {
			// First check the video isn't in a hidden tab or modal
			let parentTab = $(video).closest('.collapse');
			let parentModal = $(video).closest('.modal');
			
			if((parentTab.length && !parentTab.hasClass("show")) || (parentModal.length && !parentModal.hasClass("show"))) {
				console.log("hidden video, skip", parentTab, parentTab.length);
				return;
			}

			if(video.paused && !$(video).hasClass("playing")) {
				let distance = $(video).offset().top - $(window).scrollTop() - $(window).height();

				if (distance < (-.8 * $(video).height())) {
					$(video).addClass("playing"); // As there's a short delay before playing, this stops multiple play calls
					this.playVideo(video, delay || 500);
				}
			}
		})
	}

	// Repeat a single video
	// To make it obvious that it's refreshing, we fade out, reset the video to 0, fade back in, then play the video after a delay
	repeatVideo(e) {
		console.log("Install.repeatVideo()");
		let video = e.currentTarget;
		let speed = 'slow';
		
		setTimeout(() => {
			$(video).fadeOut(speed, function(){
				video.currentTime = 0;
				$(video).fadeIn(speed);
			});
			
			this.playVideo(video, 1500);
		}, 2000);
	}

	playVideo(video, delay) {
		console.log("Install.playVideo()", video, delay);

		setTimeout(() => {
			let isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;

			if (!isPlaying) {
				video.play();
			}
		}, delay || 0);
	}

	showToast() {
		console.log("Install.showToast()");
		let cssPulse = "animate animate--pulse-box";
		let cssShow  = "show";
		let cssFade  = "animate animate--fadeup";
		let toast    = $(".js-install-toast");

		// If toast is hidden, show it
		if(!toast.hasClass(cssShow)) {
			toast.addClass(cssShow + " " + cssFade);
			return;
		}
		// If already visible, pulse (remove then re-add to ensure it animates with every click)
		else if(toast.hasClass(cssPulse)) {
			toast.removeClass(cssPulse);

			setTimeout(() => {
				this.showToast();
			}, 300);

			return;
		}

		toast.removeClass(cssFade);
		toast.addClass(cssPulse);
	}

	// Toggle the install/continue buttons on the signup/app page - used because the clickButton() function above doesn't work on iOS 
	toggleCtas() {

	}
}

module.exports = Install;