// Display a C4C modal when user clicks Shop & Raise
// Must only be triggered if the modal exists!

'use strict';

module.exports = C4CInstead;

function C4CInstead() {
	$(".js-track-confirmed").click(function() {
		$("#modal-c4c-instead").modal('show');
		return false;
	});
}