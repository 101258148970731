// claims.js

const dayjs = require("Vendor/dayjs");

module.exports = function Claims() {

	this.init = function() {
		console.log("Claims.init()");

		self.bindEventHandlers();

		// Preloading on transaction details (e.g. if page reloaded with errors)
		if($("#date").val()) {
			console.log("We have a date already, trigger times and rates");
			self.loadTimes($("#js-visitId").val() || null);

			// Load rates if incorrect/declined donation, as no <select> so no trigger
			if(!$(".js-visit-time").length){
				self.loadRates();
			}
		}
	}

	this.bindEventHandlers = function() {
		//
		// Stage: Select merchant
		//

		$(".js-reason").change(function() {
			self.loadMerchants(this.value);
		});

		//
		// Stage: Select incorrect/declined transaction
		//

		$(".js-claim-select").click(function(e) {
			self.selectTransaction(e);
		});

		//
		// Stage: Transaction details
		//

		$(".js-visit-date").change(function()  {
			self.loadTimes();
		});

		$(".js-visit-time").change(function()  {
			self.loadRates();
		});
	}

	// Stage: Select merchant
	this.loadMerchants = function(claimType) {
		console.log("Claim.loadMerchants(" + claimType + ")");

		// Get the select list
		var list = $("#merchantId");
		list.empty();

		// Loop through merchants, add to the list
		$(".js-merchant--" + claimType).each(function(){
			var merchant = $(this).data();
			list.append($("<option/>").val(merchant.id).text(merchant.name));
		});

		// Hide error, show dropdown list
		$('.js-no-claim').hide();
		$(".js-no-donations").collapse('hide');
		$(".js-merchant-section").collapse('show');

		var hasNoMerchants = list.children().length == 0;

		$('.js-select-merchant').collapse(hasNoMerchants ? 'hide' : 'show');

		// Show error
		if(hasNoMerchants) {
			$(".js-no-donations--" + claimType).collapse('show');
		}

		// Show/hide the buttons (as they have different form actions)
		var buttons = $(".js-submit");
		buttons.each((i,button) => {
			var selected = $(button).data('type') == claimType;
			$(button).prop('disabled', !selected || hasNoMerchants);
			$(button).toggle(selected);
		})
		
		$("#confirm_continue").prop('disabled', hasNoMerchants);
	}

	// Stage: Select incorrect/declined transaction
	this.selectTransaction = function(e) {
		console.log("Claims.selectTransaction()");

		$("#confirm_continue").prop('disabled', false);

		var data = $(e.currentTarget).data();

		$.each(data, function(field, value) {
			$("#" + field).val(value);
		});
	}
	
	// Stage: Transaction details
	this.getTimes = function() {
		var options = $(".js-visit-time");
		
		options.empty();
		options.append($("<option/>").val("").text("Choose time:"));
		options.attr('disabled', true);
	
		return options;
	}

	// Stage: Transaction details
	this.loadTimes = function(visitId) {
		console.log("Claims.loadTimes(" + (visitId ? visitId : '') + ")");

		$.ajax({
			type: "POST",
			url: "/account/claim/list-visit-times",
			data: { 
				merchantId: $('#merchantId').val(), 
				claimDate: $('#date').val() 
			},
			dataType: "json",
			success: function(data){
				// Get and clear the select
				var options = self.getTimes();

				// Load the times into the select
				$.each(data, function(i, item) {
					options.append($("<option/>").val(item.id).text(dayjs(item.submitted).format("HH:mm:ss")).attr("selected", data.length == 1));
					options.removeAttr("disabled");
				});

				if(data.length == 1) {
					$(".js-visit-time").trigger('change');
				}

				// Preselect an option
				if(visitId) {
					options.val(visitId);
					options.change();
				}
			}
		});
	}

	// Stage: Transaction details
	this.getRates = function() {
		var options = $("#rate");
		
		options.empty();
		options.append($("<option />").val("").text("Choose donation expected:"));
		options.attr('disabled', true);

		return options;
	}

	// Stage: Transaction details
	this.loadRates = function() {
		console.log("Claims.loadRates()");

		$.ajax({
			type: "POST",
			url: "/account/claim/list-merchant-rates",
			data: {
				visitId: $('#visitId').val()
			},
			dataType: "json",
			success: function(data){
				// Get and clear the select
				var options = self.getRates();

				// Load the times into the select
				$.each(data, function(i, item) {
					var val = item.prettyValue;
					options.append($("<option/>").val(val).text(val).attr("selected", data.length == 1));
					options.removeAttr("disabled");
				});

				var rate = $("#js-rate").val() || null;

				// Preselect an option
				if(rate) {
					options.val(rate);
					options.change();
				}
			}
		});
	}

	var self = this;
	self.init();
}