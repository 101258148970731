const Cookie = require('Vendor/js-cookie');
const Searchbox = require('Scripts/gayl/searchbox');
require('Vendor/jquery-circle-progress');

module.exports = Dashboard;

function Dashboard() {
	
	this.init = function() {
		self.feedback();
		setTimeout(() => { self.profile() }, 1000); // As the width gets messed up if calculating whilst things are still loading

		// Set up the extra search box
		new Searchbox($("#js-dashboard-search"));
	};

	this.profile = function() {
		console.log("Dashboard.profile()");

		// Get the stuff
		var circle   = $('#infobox__progress');
		var perc     = circle.data('perc');
		var width    = Math.floor($(circle).width());
		var label    = $(circle).find('b');
		var complete = 0;

		// Animate the circle then update label
		$(circle).circleProgress({
			startAngle: -Math.PI/2,
			value: perc,
			size: width,
			lineCap: 'round',
			fill: '#00973a'
		}).on('circle-animation-progress', function(event, animationProgress, stepValue){
			// Have we passed 100% yet?
			if(complete == 0 && stepValue >= 1){
				$(this).addClass('complete');
				complete = 1;
			}
			// Update the label
			var text = Math.floor(stepValue * 100) + '%';
			$(label).html(text);
		});
	};

	this.feedback = function() {
		var container = $(".js-dashboard-feedback");

		container.find("a").on("click", (e) => {
			console.log("Dashboard.feedback()", $(e.originalTarget).hasClass('text-green') ? "Thumbs up!" : "Thumbs down!");
			Cookie.set('dashboard-feedback', true, {expires:100});
		});
	};

	var self = this;
	self.init();
}