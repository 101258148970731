// When a user clicks a "Shop now" link

module.exports = PreShop;

function PreShop() {
	var self = this;
	var LOGIN_URI = "/login?rurl=";
	var SIGNUP_URI = "/signup?rurl=";

	this.init = function() {
		this.ui = {
			login  : $('.js-track-prompt-target-login'),
			signup : $('.js-track-prompt-target-signup'),
			skip   : $('.js-track-prompt-target-skip'),
		}

		// The buy link is hidden to avoid user clicking on it before event handler is added. Now that the handler is added, so show the link.
		$(".js-track-confirmed").css("visibility", "visible");

		// Bind event handlers
		$(document).on('click', '.js-track-prompt', this.login.bind(this));
		$(document).on('click', '.js-track-confirmed', this.shop.bind(this));
	}

	// User is not logged in - prompt to log in or sign up
	this.login = function(e) {
		console.log("PreShop.login");
		var data = $(e.currentTarget).data();
		var escapedClickthrough = encodeURIComponent(data.clickthrough);

		var voucherAttr = 'fullVoucher';
		var voucher = data.fullVoucher || "";

		this.ui.login.attr('href', LOGIN_URI + escapedClickthrough);
		this.ui.signup.attr('href', SIGNUP_URI + escapedClickthrough);
		this.ui.skip.attr('href', data.clickthrough);

		this.ui.login.data(voucherAttr, voucher);
		this.ui.signup.data(voucherAttr, voucher);
		this.ui.skip.data(voucherAttr, voucher);
	}

	this.shop = function(e) {
		var element = $(e.currentTarget);
		var destinationUrl = element.attr('href');
		var forced = element.data('force');

		// This is really crap
		if (GAYL.adblockEnabled && !forced) {
			this.populateAdblockModal($.extend(element.data(), {url: destinationUrl}));
			this.openAdblockModal();
		}
		else {
			// Open a new tab to the retailer if there is an actual link
			if (destinationUrl && destinationUrl !== '#') {

				if (window.GiveasyouLiveBHO) {
					var call = "GiveasyouLive.Poll.setTracking('https://" + window.location.hostname + destinationUrl.replace(/'/g, "\\'") + "')";
					window.GiveasyouLiveBHO.callback(call);
				}
				window.open(destinationUrl, '_blank');
			}

			// Populate dynamic data + open modal
			this.populateTrackingConfirmedModal(element);
			this.openTrackConfirmedModal();
		}

		e.preventDefault();
	}

	this.populateTrackingConfirmedModal = function(element) {
		var store = {
			name: element.data('storeName') || 'this store'
		};

		$('.js-store-name-target').each(function() {
			$(this).html(store.name);
		});
		
		var code         = element.data('fullVoucher');
		var voucherCodes = $('.js-voucher-code-target');
		
		if(typeof code !== "undefined" && code != "") {
			voucherCodes.each(function() {
				$(this).find("span").html(code);
				$(this).collapse('show');
			});
		}
		else {
			voucherCodes.collapse('hide');
		}
	}

	this.populateAdblockModal = function(data) {
		var adblockButton = $('.js-adblock-url-target');
		if(data.url) {
			adblockButton.attr('href', data.url);
		}
		if(data.storeName) {
			adblockButton.attr('data-store-name', data.storeName);
		}
		if(data.fullVoucher) {
			adblockButton.attr('data-full-voucher', data.fullVoucher);
		}
	}

	this.openAdblockModal = function() {
		console.log("PreShop.openAdblockModal()");
		$('.js-adblock-enabled-modal').modal('show');
	}

	this.openTrackConfirmedModal = function() {
		console.log("PreShop.openTrackConfirmedModal()");
		$('.js-post-shop-modal').modal('show');
	}

	self.init();
}