// Reveal voucher code button
// Used on lots of different pages so a global module

'use strict';

module.exports = Voucher;

function Voucher() {
	$(document).on('click', '.js-offer-revealcode', function(e) {
		console.log("Show voucher code");
		// Show code
		var button = $(e.currentTarget);
		var toReveal = button.parent().find('.js-offer-reveal');
		button.hide();
		toReveal.show();
		copyCode(button);
	});

	$(document).on('click', '.js-offer-voucher', function(e) {
		copyCode($(e.currentTarget));
	});

	function copyCode(button) {
		var code = button.data('full-voucher');
		var box = document.createElement("textarea");
		box.innerHTML = code;
		document.body.appendChild(box);
		box.select();
		document.execCommand('copy');
		document.body.removeChild(box);
	}
}