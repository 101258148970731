/**
 * Store search JS.
 *
 * @author Daniel Moffat
 */

const Cookie          = require('Vendor/js-cookie');
const miuri           = require('Vendor/miuri.js').miuri;
const ModalQueue      = require("Scripts/common/modal-queue");

// Below has to be an import in order to work
import FavouriteStores from "Scripts/gayl/favourite-stores";

export default class StoreSearch {
	constructor() {
		// Contains the main state of the search, i.e. what query has been used, what page we're on, etc.
		this.state = this.getInitialState();
		
		// Contains the current state of our filter toggles (whether they're open or not.)
		this.filterToggleState = {
			category: true,
			letter: true,
			main: false,
			sort: true
		};

		new ModalQueue(); // attaches to window.modalQueue, there should be only one of these objects.
		
		this.bindEventHandlers();
		this.bindHistory();
		this.autoPopupFavouritesModal();
	};

	// Selects all the elements we'll be using and gives them nice names
	selectUi() {
		this.ui = {
			content: $('.js-store-search-content'),
			categoryBtn: $('.js-select-category'),
			clearFilterBtn: $('.js-clear-filters'),
			filterContainer: $('.js-filter-container'),
			letters: $('.js-select-letter'),
			loading: $('.js-loading-state'),
			modal: $('#modal-search-filter'),
			pagination: {
				pages: $('.js-select-page'),
				prev: $('.js-prev-page'),
				next: $('.js-next-page')
			},
			removeLetterBtn: $('.js-remove-letter'),
			removeParentCategoryBtn: $('.js-remove-parent-category'),
			removeChildCategoryBtn: $('.js-remove-child-category'),
			root: $('.js-store-search-root'),
			sortSelect: $('.js-sort')
		};
	};

	bindEventHandlers() {
		this.selectUi();

		this.ui.categoryBtn.on('click', this.onSelectCategory.bind(this));
		this.ui.removeParentCategoryBtn.on('click', this.onRemoveParentCategory.bind(this));
		this.ui.removeChildCategoryBtn.on('click', this.onRemoveChildCategory.bind(this));
		this.ui.sortSelect.on('change', this.onSortChanged.bind(this));
		this.ui.letters.on('click', this.onSelectLetter.bind(this));
		this.ui.removeLetterBtn.on('click', this.onRemoveLetter.bind(this));
		this.ui.pagination.pages.on('click', this.onSelectPage.bind(this));
		this.ui.pagination.prev.on('click', this.onSelectPrevPage.bind(this));
		this.ui.pagination.next.on('click', this.onSelectNextPage.bind(this));
		this.ui.clearFilterBtn.on('click', this.onClearFilters.bind(this));
	};

	/**
	 * The favourite stores modal displays a button to "Add more favourites", which just links to the store search with a hash.
	 * We look for this hash and autopopup the modal if needed.
	 */
	autoPopupFavouritesModal() {
		console.log("StoreSearch.autoPopupFavouritesModal");
		if(window.location.hash === "#modal=favourites") {
			// Whenever I call $('.modal').show(), they don't close properly unless they are opened this way. No queueing is needed here.
			window.modalQueue.add({
				id: "js-favourite-help-modal",
				priority: 1
			});
		}
	};

	/**
	 * Listens to changes in browser history, cannot be within bindEventHandlers as that function gets called each time an action occurs,
	 * resulting in 'popstate' event handlers getting bound multiple times.
	 */
	bindHistory() {
		console.log("StoreSearch.bindHistory");
		$(window).on('popstate', this.onPageChanged.bind(this));
	};

	/**
	 * Parses the current document's URL  to populate some local state which we'll need later when rebuilding URLs when stuff is clicked.
	 * @param existingUrl If supplied, will use this rather than the current document's URL.
	 */
	getInitialState(existingUrl) {
		console.log("StoreSearch.getInitialState");
		var uri = existingUrl ? new miuri(existingUrl) : new miuri();
		var state = uri.query();
		var categories = this.extractCategories(uri.path());
		return this.merge(categories, state);
	};

	/**
	 * Merge all properties on src with the target, overwriting any that already exist.
	 */
	merge(src, target) {
		for(var key in src) {
			target[key] = src[key];
		}
		return target;
	};

	/**
	 * Called when the back / forward buttons are pressed.
	 */
	onPageChanged(e) {
		console.log("StoreSearch.onPageChanged");
		this.state = this.getInitialState(document.location.href); // Recalculate the state
		this.search(false); // Don't create history entry with this search
	};

	onClearFilters(e) {
		console.log("onClearFilters");
		this.state.sort = null;
		this.state.parentCategory = null;
		this.state.childCategory = null;
		this.state.letter = null;
		this.state.page = null;
		this.search(true);
	};

	onSelectCategory(e) {
		console.log("StoreSearch.onSelectCategory", $(e.currentTarget).text().trim());
		var clickedEl = $(e.currentTarget);
		var newPath = clickedEl.attr('href');
		var newCategories = this.extractCategories(newPath);
		this.state.sort = null;
		this.state.parentCategory = newCategories.parentCategory;
		this.state.childCategory = newCategories.childCategory;
		this.state.page = null;
		this.search(true);
		e.preventDefault(); // Don't follow the links
	};

	onRemoveParentCategory(e) {
		console.log("StoreSearch.onRemoveParentCategory", $(e.currentTarget).parent("span").text().trim());
		this.state.childCategory = null;
		this.state.parentCategory = null;
		this.state.page = null;
		this.search(true);
	};

	onRemoveChildCategory(e) {
		console.log("StoreSearch.onRemoveChildCategory", $(e.currentTarget).parent("span").text().trim());
		this.state.childCategory = null;
		this.state.page = null;
		this.search(true);
	};

	onSortChanged(e) {
		console.log("StoreSearch.onSortChanged");
		var clickedEl = $(e.currentTarget);
		var sortVal = clickedEl.val();
		this.state.sort = sortVal;
		this.state.page = null;
		this.search(true);
	};

	onSelectLetter(e) {
		console.log("StoreSearch.onSelectLetter", $(e.currentTarget).text().trim());
		var clickedEl = $(e.currentTarget);
		var letter = clickedEl.data('letter');
		this.state.letter = letter;
		this.state.page = null;
		this.search(true);
	};

	onRemoveLetter(e) {
		console.log("StoreSearch.onRemoveLetter", $(e.currentTarget).text().trim());
		this.state.letter = null;
		this.state.page = null;
		this.search(true);
	};

	onSelectPage(e) {
		console.log("StoreSearch.onSelectPage");
		var clickedEl = $(e.currentTarget);
		var newPage = clickedEl.data('page');
		this.state.page = newPage;
		this.search(true);
		e.preventDefault();
	};

	onSelectPrevPage(e) {
		console.log('StoreSearch.onSelectPrevPage');
		var currentPage = this.state.page;
		this.state.page = currentPage - 1;
		this.search(true);
		e.preventDefault();
	};

	onSelectNextPage(e) {
		console.log('StoreSearch.onSelectNextPage');
		var currentPage = this.state.page;
		this.state.page = currentPage + 1;
		this.search(true);
		e.preventDefault();
	};

	/**
	 * Given a path, will extract the categories from it.
	 * E.g. /search/stores/christmas/gifts will produce:
	 * { parentCategory: 'christmas', childCategory: 'gifts' }
	 */
	extractCategories(path) {
		console.log("StoreSearch.extractCategories");
		var pathBits = path.split('/');
		return {
			parentCategory: pathBits[3],
			childCategory: pathBits[4]
		};
	};

	/**
	 * Updates the browsers URL to the given URL.
	 * It gets rid of the "partial" parameter used to make AJAX requests as the AJAX endpoint URL is also used as the new URL.
	 */
	updateUrl(newUrl) {
		console.log("StoreSearch.updateUrl", newUrl);
		var uri = new miuri(newUrl);
		delete uri.parts.query.partial; // Hack, library doesn't support deleting params directly.
		window.history.pushState({}, '', uri.toString());
		document.title = this.ui.root.data('title');

		var loc = window.location;
		$('link[rel="canonical"]').attr('href', loc.origin + loc.pathname); // Don't use uri as missing the domain
	};

	/**
	 * @return The endpoint to query for store search results. This takes into account any filters/sorts
	 * that have been applied.
	 */
	getEndpoint() {
		console.log("StoreSearch.getEndpoint");
		var endpoint = '/search/stores';
		if(this.state.parentCategory) {
			endpoint += '/' + this.state.parentCategory;
			if(this.state.childCategory) {
				endpoint += '/' + this.state.childCategory;
			}
		}
		var uri = new miuri(endpoint); // Use miuri lib to construct the URI easily.

		// Loop through the state variables and add them to the query string, ignoring specific items.
		// dm is a special parameter used when the user visits this page after visiting the clickthrough for a non-existent retailer.
		var keysToIgnore = ['parentCategory', 'childCategory', 'dm', 'dp'];
		
		for(var key in this.state) {
			if(this.state[key]) {
				if(keysToIgnore.indexOf(key) === -1) {
					uri.query(key, this.state[key]);
				}
			}
		}
		
		uri.query('partial', true); // This special parameter makes the endpoint return only the search HTML without the wrapper.

		console.log("StoreSearch.getEndpoint", uri.toString());
		return uri.toString();
	};

	/**
	 * Performs a store search and puts the result HTML into the DOM + rebinds the event handlers.
	 * @param createHistoryEntry, whether to add new entry to the browser's history after peforming the search.
	 */
	search(createHistoryEntry) {
		console.log("StoreSearch.search");
		var endpoint = this.getEndpoint();
		this.ui.loading.show();
		this.ui.content.hide();
		this.ui.modal.modal('hide');
		
		$.ajax({
			method: 'GET',
			url: endpoint
		}).then(function(resp) {
			console.log("StoreSearch.search", "Ajax returned");
			this.ui.root.replaceWith(resp);
			this.copyFiltersToModal();
			this.bindEventHandlers();
			new FavouriteStores();
			if(createHistoryEntry) {
				console.log("StoreSearch.search", "has createHistoryEntry");
				this.updateUrl(endpoint);
			}
		}.bind(this));
	};

	// This keeps the filter modal in sync with the sidebar
	copyFiltersToModal() {
		console.log("Search.copyFiltersToModal")
		const filtersHtml = $(".js-filters-container").html(); // Don't put in this.ui
		this.ui.modal.find(".modal-body").html(filtersHtml);
	};
};